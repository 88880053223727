.page-content {
  margin-top: 50px;
  margin-left: 250px;
  position: absolute;
  width: calc(100% - 260px);
  transition: all 0.4s ease;
  padding-left: 1rem !important;
}

.page-content.toggled {
  margin-left: 50px;
  width: calc(100% - 4.9rem);
}

.sidebar.toggled {
  width: 4.6rem;
  overflow-x: hidden;
  transition: all 0.4s ease;
}

.sidebar.toggled .sidebar-text {
  font-size: 0;
  /* transition: all 0.7s ease; */
}

.sidebar.toggled .version {
  font-size: 0;
  /* transition: all 0.7s ease; */
}

.info-box {
  height: 90% ;
}

.info-box h3 {
  font-weight: 700;
  font-size: 2.2rem;
}

.card-title {
  float: left;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  background-color: inherit;
}

.card-header {
  background-color: white;
}



@media (max-width: 768px) {
  .page-content {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    width: auto;
  }

  .page-content.toggled {
    margin-left: 0px;
    width: auto;
  }

  .sidebar {
    width: 250px;
    transition: all 0.4s ease;
  }

  .sidebar.toggled {
    width: 0px;
  }
}

@media (max-width: 1080px) {
  .page-content {
    position: relative;
    margin-top: 70px;
    width: auto;
    margin-right: 10px;
  }
}