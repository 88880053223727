  .ratings{
      margin-right:10px;
  }
  
  .ratings i{
      
      color:#cecece;
      font-size:32px;
  }
  
  .rating-color{
      color:#fbc634 !important;
  }
  
  .review-count{
      font-weight:400;
      margin-bottom:2px;
      font-size:24px !important;
  }
  
  .small-ratings i{
    color:#cecece;   
  }
  
  .review-stat{
      font-weight:300;
      font-size:18px;
      margin-bottom:2px;
  }