
.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
  z-index: 999;
}
.leaflet-control-zoom-fullscreen {
  background-image: url('src/assets/icons/icon-fullscreen.png');
}
.leaflet-retina .leaflet-control-zoom-fullscreen {
  background-image: url('src/assets/icons/icon-fullscreen-2x.png');
  background-size: 26px 26px;
}
