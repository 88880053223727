html {
  font-family: Tahoma, serif;
  font-size: 12px;
}

body {
  font-family: Tahoma, serif;
  font-size: 12px;
  background-color: #f8f9fa;
  height: auto;
}

fieldset {
  border: 1px solid gray;
}

legend {
  font-size: 100%;
}

.card {
  margin-bottom: 10px;
  box-shadow: inset 0 3px 10px rgba(black, .075);
}

.row {
  margin-top: 10px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input.ng-valid[required]  {
  border-left: 3px solid #42A948;
}

input.ng-touched.ng-invalid {
  border-left: 3px solid #a94442;
}

.ng-valid[required] > div > input {
  border-left: 3px solid #42A948;
}

.pg-component.ng-touched.ng-invalid {
  border-left: 3px solid #a94442;
}

.fill {
  min-height: 100%;
  height: 100%;
}

svg {
  /*border: 1px solid #999;*/
  overflow: hidden;
  direction: ltr;
}

label {
  font-weight: 700;
}

.bg-success {
  color: #fff !important;
  background-color: #28a745 !important;
}

.bg-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
}

.bg-primary {
  color: #fff !important;
  background-color: #007bff !important;
}

.bg-warning {
  color: #fff !important;
  background-color: #ffc107 !important;
}

.bg-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
}

.bg-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
}

.bg-navy {
  color: #fff !important;
  background-color: #001f3f !important;
}

.text-green {
  color: #28a745 !important;
}

.text-yellow {
  color:  #ffc107 !important;
}

.text-red {
  color:  #dc3545 !important;
}

.text-blue {
  color: #007bff !important;;
}

small, .small {
  font-size: 80%;
  font-weight: 400;
}
